import { render, staticRenderFns } from "./condAdminUserRights.vue?vue&type=template&id=767120b8&scoped=true"
import script from "./condAdminUserRights.vue?vue&type=script&lang=js"
export * from "./condAdminUserRights.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767120b8",
  null
  
)

export default component.exports