<template>
  <v-container v-if="tmpRights != null && orgSettings != null">
    <v-row v-if="orgSettings.showConditions === true">
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.userRights.conditions') }}</v-label>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.readCondition.allowed"
          :label="$t('condAdmin.userRights.read')"
          @change="changedReadCondition"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.manageCondition.allowed"
          :label="$t('condAdmin.userRights.manage')"
          :disabled="!tmpRights.readCondition.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.showXlsImport === true && !isDaagChild"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.uploadCondition.allowed"
          :label="$t('condAdmin.userRights.upload')"
          :disabled="!tmpRights.readCondition.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.publishing.examiners > 0"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.examineCondition.allowed"
          :label="$t('condAdmin.userRights.examine')"
          :disabled="!tmpRights.readCondition.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.publishing.activatorRole === true"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.activateCondition.allowed"
          :label="$t('condAdmin.userRights.activate')"
          :disabled="!tmpRights.readCondition.allowed"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="orgSettings.showResidualValues === true">
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.userRights.residualValues') }}</v-label>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.readResidualValues.allowed"
          :label="$t('condAdmin.userRights.read')"
          @change="changedReadResidualValues"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.manageResidualValues.allowed"
          :label="$t('condAdmin.userRights.manage')"
          :disabled="!tmpRights.readResidualValues.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.showXlsImport === true && !isDaagChild"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.uploadResidualValues.allowed"
          :label="$t('condAdmin.userRights.upload')"
          :disabled="!tmpRights.readResidualValues.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.publishing.examiners > 0"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.examineResidualValues.allowed"
          :label="$t('condAdmin.userRights.examine')"
          :disabled="!tmpRights.readResidualValues.allowed"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="orgSettings.publishing.activatorRole === true"
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.activateResidualValues.allowed"
          :label="$t('condAdmin.userRights.activate')"
          :disabled="!tmpRights.readResidualValues.allowed"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="orgSettings.showBaseData === true">
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.userRights.baseData') }}</v-label>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.readBaseData.allowed"
          :label="$t('condAdmin.userRights.read')"
          @change="changedReadBaseData"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-checkbox
          v-model="tmpRights.manageBaseData.allowed"
          :label="$t('condAdmin.userRights.manage')"
          :disabled="!tmpRights.readBaseData.allowed"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defaultUserRights, defaultOrgSettings } from '@/condAdmin/js/condDefaults.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import deepmerge from '@/base/js/Deepmerge.js';

export default {
  name: 'condAdminUserRights',
  components: {
  },
  props: {
    userRights: { type: Object, required: true }
  },
  data () {
    return {
      tmpRights: null,
      orgSettings: null
    };
  },
  computed: {
    // orgSettings: {
    //   get: function () {
    //     return deepmerge(defaultOrgSettings, (this.$store.state.base.admin.administratedOrgObjects.moduleSettings.condAdminOrgSettings || {}));
    //   },
    //   set: function (newValue) {
    //     this.$store.state.base.admin.administratedOrgObjects.moduleSettings.condAdminOrgSettings = newValue;
    //   }
    // }
    isDaagChild () {
      return this.$store.state.base.admin.administratedOrgObjects.orgSettings.parent === 'daag';
    }
  },
  watch: {
    userRights () {
      this.tmpRights = deepmerge(defaultUserRights, this.userRights);
    }
  },
  async mounted () {
    this.tmpRights = deepmerge(defaultUserRights, this.userRights);
    if (this.$store.state.base.admin.administratedOrgObjects.moduleSettings.condAdminOrgSettings == null) {
      await this.getParentsCondAdminOrgSettings();
    } else {
      this.orgSettings = this.$store.state.base.admin.administratedOrgObjects.moduleSettings.condAdminOrgSettings;
    }
  },
  methods: {
    async getParentsCondAdminOrgSettings () {
      let ioData = {
        targetKey: this.$store.state.base.admin.administratedOrgObjects.orgSettings.parentUid,
        context: '',
        contextFilter: 'OrgSettings'
      };
      this.$restClient.callProcess('baseAdmin', 'globalPropertiesGetList', ioData, true).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          this.orgSettings = processData.ioData.globalProps.condAdminOrgSettings;
        } else {
          this.orgSettings = defaultOrgSettings;
          this.$globals.Info.open(processData.processState.messages);
        }
      });
    },
    getUserRights () {
      // let userRights = {};
      // for (const key of Object.keys(this.tmpRights)) {
      //   if (this.tmpRights[key].allowed === true) userRights[key] = this.tmpRights[key];
      // }
      // return userRights;
      return this.tmpRights;
    },
    changedReadBaseData (value) {
      if (!value) {
        this.tmpRights.manageBaseData.allowed = false;
      }
    },
    changedReadCondition (value) {
      if (!value) {
        this.tmpRights.manageCondition.allowed = false;
        this.tmpRights.uploadCondition.allowed = false;
        this.tmpRights.examineCondition.allowed = false;
        this.tmpRights.activateCondition.allowed = false;
      }
    },
    changedReadResidualValues (value) {
      if (!value) {
        this.tmpRights.manageResidualValues.allowed = false;
        this.tmpRights.uploadResidualValues.allowed = false;
        this.tmpRights.examineResidualValues.allowed = false;
        this.tmpRights.activateResidualValues.allowed = false;
      }
    }
  }
};
</script>

<style scoped>
</style>
